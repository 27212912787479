<template>
    <table width="100%" border="0" cellspacing="0" cellpadding="0" summary="In questa tabella è riassunto il dettaglio dell'ordine, con la possibilità di aggiungere i prodotti,se disponibili, nel carrello dela spesa.">
        <tbody>    
            <tr class="TableHead">
                <th scope="col" class="TableItemCode">Cod. articolo</th>
                <th scope="col" class="TableItemDescription">Descrizione</th>
                <!--<th scope="col" class="TableItemVarA">Taglia</th>-->
                <th scope="col" class="TableItemQty">Qtà</th>
                <th scope="col" class="TableItemPrice">Prezzo</th>
            </tr>
            <RowOrdineDettaglio
                v-for="riga in this.listaRighe"
                :key="riga.IdRiga"
                :RigaOrdine="riga"
            />
        </tbody>    
    </table>
</template>

<script>
import RowOrdineDettaglio from "@/components/RowOrdineDettaglio.vue";
export default {
    name:"GrigliaOrdine",
    components:{
        RowOrdineDettaglio
    },
    props:{
        listaRighe: Array
    }
}
</script>
<template>
    <tr>
        <td class="TableItemCode"><small>Codice articolo</small><span>{{this.CodiceArticolo}}</span></td>
        <td class="TableItemDescription"><small>Descrizione</small><span>{{this.DescrArticolo}}</span></td>
        <!--<td class="TableItemVarA"><small>Taglia</small><span>L</span></td>-->
        <td class="TableItemQty"><small>Quantità</small><span>{{this.Quantita}}</span></td>
        <td class="TableItemPrice"><small>Prezzo</small><span>{{this.TotaleRiga}} </span></td>
    </tr>
</template>
<script>
export default {
    name:"RowOrdineDettaglio",
    props: {
        RigaOrdine: Object
    },
    computed:{
        CodiceArticolo: function(){
            return this.RigaOrdine.CodiceArticolo ?? "-";
        },
        DescrArticolo: function(){
            return this.RigaOrdine.DescrizioneArticolo ?? "-";
        },
        Quantita: function(){
            return this.RigaOrdine.Quantita ?? "-";
        },
        TotaleRiga: function(){
            if(this.RigaOrdine.Prezzo != null){
                return "€ " + (this.RigaOrdine?.Prezzo * this.RigaOrdine?.Quantita);
            }else{
                return "-";
            }
        }
    }
}

</script>
<template>
    <section class="WhiteSmoke">
        <div class="Container">
            <div class="Box">
                <div class="Bookmark"><span>Dettaglio ordine</span></div>
            </div>
            <div class="Box DetailTable">
                <div class="Frame ">
                    <div class="DetailTableIndex Top">
                        <div class="BackButton"><router-link :to="{ name:'StoricoOrdini' }"><span><img src="images/BackButtonIcon.png" srcset="images/BackButtonIconRetina.png 2x" alt="" /><i>Indietro</i></span></router-link></div>
                        <div class="DetailTableIndexInfo">
                            <div><label>Ordine n°</label><strong>{{this.NumeroOrdine}}</strong></div>
                            <div><label>Data ordine:</label><strong>{{this.DataOrdine}}</strong></div>
                            <div><label>Spese spedizione:</label><strong>{{this.SpeseSpedizione}}</strong></div>
                            <div><label>Totale articoli:</label><strong>{{this.TotaleOrdine}}</strong></div>
                            <div><label>Totale:</label><strong>{{this.Totale}}</strong></div>
                        </div>
                    </div>
                    <GrigliaOrdine
                        v-if="this.isReady"
                        :listaRighe="this.ordine?.RigheOrdine"
                    />
                    <div class="DetailTableIndex Bottom">
                        <div class="BackButton"><router-link :to="{ name:'StoricoOrdini' }"><span><img src="images/BackButtonIcon.png" srcset="images/BackButtonIconRetina.png 2x" alt="" /><i>Indietro</i></span></router-link></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import GrigliaOrdine from '@/components/GrigliaOrdine.vue';
import { apiMixin, dateMixin } from '@/mixin.js';

export default {
    name:"SchedaOrdine",
    data(){
        return {
            ordine: {},
            idOrdine: "",
            isReady: false
        }
    },
    components:{
        GrigliaOrdine
    },
    computed:{
        NumeroOrdine: function(){
            return this.ordine?.NumeroOrdine ?? "-";
        },
        DataOrdine: function(){
            if(this.ordine != null && this.ordine.DataOrdine != null){
                return this.ordine.DataOrdine.split(' ')[0];
            }else{
                return "-";
            }
        },
        TotaleOrdine: function(){
            if(this.ordine?.TotaleOrdine){
                return "€" + this.ordine?.TotaleOrdine.toFixed(2);
            }else{
                return "0,00";
            }
        },
        Totale: function(){
            if(this.ordine?.TotaleOrdine){
                let v = parseFloat(this.ordine?.TotaleOrdine) + parseFloat(this.ordine?.SpeseSpedizione);
                return "€" + v.toFixed(2);
            }else{
                return "0,00";
            }
        },
        SpeseSpedizione: function(){
            if(this.ordine?.TotaleOrdine){
                return "€" + this.ordine?.SpeseSpedizione.toFixed(2);
            }else{
                return "0,00";
            }
        }
    },
    mixins:[apiMixin,dateMixin],
    mounted:function(){
        this.idOrdine = this.$route.params.IdOrdine;
        this.getOrdine();
    },
    watch:{
        ordine: function(newvalues){
            this.ordine = newvalues;
        }
    },
    methods:{
        getOrdine: function(){
            let shop = this.$store.getters.getShop;
            if(shop != null){
                this.doApiCall("get",shop+"/StoricoOrdini/Get?IdOrdine="+this.idOrdine,this.$store.getters.getToken).then(
                    (data) =>{
                        if(data){
                            this.ordine = data[0];
                            this.isReady = true;
                        }
                    }
                );
            }
        }
    }
}
</script>